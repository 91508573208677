<template>
  <div class="auth-device-detail">
    <app-detail :opt="opt" ref="detail"></app-detail>
  </div>
</template>
<script>
  let validIp = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg =
        /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
      if (!reg.test(value)) {
        callback(new Error("IP地址格式不对"));
      } else {
        callback();
      }
    }
  };
  let validDeviceSN = (rule, value, callback) => {
      //仅支持输入大小写字母、数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("SN地址格式不对"));
      } else {
        callback();
      }
  };

  export default {
    data() {
      let _this = this;
      return {
        opt: {
          id: "",
          title: "人证机详情",
          form: [{
              label: "省份",
              key: "province",
              type: "selectCity",
              opt: {
                list: [],
                change(data) {
                  _this.getCityList(data);
                }
              },
              isCol50: true,
              isWidth67: true,
              rules: [{
                required: true
              }]
            },
            {
              label: "城市",
              key: "city",
              type: "selectCity",
              opt: {
                list: [],
                parent: "province",
                change(data) {
                  _this.getApartmentList(data);
                }
              },
              isCol50: true,
              isWidth67: true,
              rules: [{
                required: true
              }]
            },
            {
              label: "房源名称",
              key: "apartmentId",
              type: "select",
              isCol50: true,
              isWidth67: true,
              opt: {
                multiple: false,
                list: [],
                parent: "city"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "人证机名称",
              key: "rzjName",
              isCol50: true,
              isWidth67: true,
              rules: [{
                required: true
              }]
            },
            {
              label: "设备SN",
              key: "deviceId",
              isCol50: true,
              isWidth67: true,
              rules: [{
                required: true,
                validator: validDeviceSN,
                trigger: ['blur']
              }]
            },
            {
              label: "通讯协议",
              key: "protocol",
              isCol50: true,
              isWidth67: true,
              type: "select",
              opt: {
                list: [{
                    value: "1",
                    label: "HTTP"
                  },
                  {
                    value: "2",
                    label: "RTMP"
                  },
                  {
                    value: "3",
                    label: "RTSP"
                  }
                ]
              }
            },
            {
              label: "分辨率",
              key: "resolutionRatio",
              isCol50: true,
              isWidth67: true,
              type: "select",
              opt: {
                list: [{
                    value: "1",
                    label: "1920 x 1080"
                  },
                  {
                    value: "2",
                    label: "1280 x 720"
                  },
                  {
                    value: "3",
                    label: "960 x 480"
                  },
                  {
                    value: "4",
                    label: "320 x 640"
                  }
                ]
              }
            },
            {
              label: "在线状态",
              key: "onlineFlag",
              isCol50: true,
              isWidth67: true,
              type: "select",
              rules: [{
                required: true
              }],
              opt: {
                list: [{
                    value: "0",
                    label: "离线"
                  },
                  {
                    value: "1",
                    label: "在线"
                  }
                ]
              }
            },
            {
              label: "安装位置",
              key: "area"
            },
            {
              label: "备注信息",
              key: "remark",
              type: "textarea"
            }
          ],
          buttons: [{
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              let dto = {
                city: form.city,
                province: form.province,
                deviceId: form.deviceId,
                rzjName: form.rzjName,
                ip: form.ip,
                port: form.port,
                mac: form.mac,
                protocol: form.protocol,
                resolutionRatio: form.resolutionRatio,
                onlineFlag: form.onlineFlag,
                area: form.area,
                remark: form.remark,
                apartmentId: form.apartmentId
              };

              let url = "device-service/rzjmanagment/addRzjDevice";
              let message = "添加人证机成功";
              if (_this.id != "0") {
                url = "device-service/rzjmanagment/updateRzjDeviceReq";
                dto.rzjId = _this.$route.params.id;
                dto.oldApartmentId = _this.$route.params.apartmentId;
                message = "编辑人证机成功";
              }

              _this.post(url, dto).then(function () {
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$back();
              });
            }
          }],
          get(opt) {
            let dto = {
              rzjId: opt.id
            };
            _this
              .post("device-service/rzjmanagment/queryRzjDeviceInfo", dto)
              .then(data => {
                data.createTime = data.createTime ?
                  _this.format(data.createTime) :
                  "";
                data.state = ["禁用", "正常"][data.state];
                data.cityId = Number(data.cityId);
                //   Promise.all([_this.getApartmentList(), _this.getCityList()]).then(
                //     values => {
                //       opt.cb(data);
                //     }
                //   );
                opt.cb(data);
              });
          }
        },
        apartmentId: "" // 房源id
      };
    },
    activated() {
      this.$refs.detail.title = ["添加人证机", "编辑人证机", "人证机详情"][{
        add: 0,
        edit: 1,
        show: 2
      } [this.$route.params.type]];
      console.log(this.$route.params);
      if (this.$route.params.id == "0") {
        this.id = this.$route.params.id;
        this.opt.form[1]["disabled"] = true;
        this.opt.form[2]["disabled"] = true;
      } else {
        this.id = "";
        this.opt.form[1]["disabled"] = false;
        this.opt.form[2]["disabled"] = false;
      }
      console.log("room-detail created!!");
      this.apartmentId = this.$route.query.apartmentId || "";
      this.onclickProvince();
    },
    methods: {
      //获取房源列表
      getApartmentList(cityId) {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          city: cityId
        };
        return new Promise((resolve, reject) => {
          this.post("landlord-service/apartment/queryPagesApartment", dto).then(
            res => {
              this.opt.form.forEach(data => {
                if (data.key === "apartmentId") {
                  res.forEach(item => {
                    item.label =
                      item.name + " （房东：" + item.landlordName + "）";
                    item.value = item.id;
                  });
                  data.opt.list = res;
                  resolve(res);
                }
              });
            }
          );
        });
      },
      onclickProvince() {
        this.post("/landlord-service/base/getProvinces").then(res => {
          for (let i = 0; i < this.opt.form.length; i++) {
            let data = this.opt.form[i];
            if (data.key === "province") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              break;
            }
          }
        });
      },
      // 获取城市列表
      getCityList(name) {
        this.post("/landlord-service/base/getCitys?name=" + name).then(res => {
          for (let i = 0; i < this.opt.form.length; i++) {
            let data = this.opt.form[i];
            if (data.key === "city") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              break;
            }
          }
        });
      }
    }
  };
</script>